import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { addToFavourites, getUserInfo } from "@/shared/redux/features/userSlice";
import { useParams } from "next/navigation";
import styles from "./index.module.scss";
import { Icon } from "@/shared/components/Icon";
import { useAuthModal } from "@/hooks/useAuthModal";
import clsx from "clsx";
interface IAddToWishlist {
  price: number;
  name?: string;
  id?: number;
  callBack?(): any;
}
const AddToWishlist = (props: IAddToWishlist) => {
  const {
    price,
    name,
    id: initialID,
    callBack
  } = props;
  const {
    id
  } = useParams();
  const {
    handleOpenAuthModal
  } = useAuthModal();
  // const [isWishList, setIsWishList] = useState<boolean>(false);
  const user = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const productID = initialID || id;
  const isWishList = user.info.favourites.find(id => id === Number(productID));
  const handleClick = () => {
    if (!user.auth.isAuth) {
      handleOpenAuthModal();
    } else {
      dispatch(addToFavourites({
        id: productID,
        price
      }));
    }
    if (callBack) {
      callBack();
    }
  };
  return <>
      <button onClick={handleClick} className={styles.favourite} title={!!name?.trim() ? `Добавить в избранное ${name}` : undefined}>
        <Icon name={"Favourite"} className={clsx(styles.icon, isWishList && styles.active)} stroke="var(--grey-darker-color)" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      </button>
    </>;
};
export default AddToWishlist;